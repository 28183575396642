import { FcCalendar } from "react-icons/fc";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Group } from "@mantine/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { Rating } from "@mantine/core";

const OchiqmalumotlarItem = ({ item }) => {
  const [opened, { open, close }] = useDisclosure(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  let date = new Date(item.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  let dates = new Date(item.update_time);
  let dateMDYN = `${date.getDate()}-${
    dates.getMonth() + 1
  }-${dates.getFullYear()}`;

  return (
    <div className="shadow-sm p-2 border rounded-md mt-4 hover:bg-gray-100 duration-500">
      <div>
        <Modal
          opened={opened}
          onClose={close}
          withCloseButton={false}
          size="90%"
          centered
        >
          <h3 className="text-center py-[20px] font-semibold text-xl">
            {item.title}
          </h3>

          <div className="flex justify-between">
            <div className="w-[60vw] h-[100vh]">
              {item.post_file ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={item.post_file}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              ) : (
                ""
              )}
            </div>

            <div className="flex flex-col gap-4 w-[15vw]">
              <h5>Ma'lumotlar to'plamlari</h5>
              <p>Ma'lumotlar to'plami egasi</p>
              <p>Mas'ul shaxs bilan bog'lanish</p>
              <form>
                <div>
                  <input
                    className="bg-gray-200 px-3 mb-2"
                    type="text"
                    placeholder="F.I.O."
                  />
                  <div>
                    <input
                      className="bg-gray-200 px-3 mb-2"
                      type="number"
                      placeholder="telefon raqamingiz"
                    />
                  </div>
                  <textarea className="bg-gray-200 px-5 mb-2" />
                </div>
                <div>
                  <Rating size={20} />
                </div>
                <button
                  className="bg-sky-600 px-2 mt-4 rounded text-white"
                  type="submit"
                >
                  Yuborish
                </button>
              </form>
            </div>
          </div>
        </Modal>
        <Group position="start" style={{ cursor: "pointer" }}>
          <div onClick={open}>{item.title}</div>
        </Group>
      </div>

      <div className="flex justify-between mt-[10px] pb-2">
        <div>
          <p>Qo'shilgan vaqti</p>
          <span className="flex items-center gap-2">
            <FcCalendar size={18} />
            {dateMDY}
          </span>
        </div>
        <div>
          <p>O'zgartirilgan vaqti</p>
          <span className="flex items-center gap-2">
            <FcCalendar size={18} />
            {dateMDYN}
          </span>
        </div>
      </div>
    </div>
  );
};
export default OchiqmalumotlarItem;
