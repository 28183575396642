import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";

const NewsHeroItem = ({ item }) => {
  const [readMore, setReadMore] = useState(false);
  let date = new Date(item.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()} / ${date.getHours()} : ${date.getMinutes()}`;
  return (
    <div className="m-2 hover:shadow-2xl duration-700 p-2 shadow-xl border border-gray-300">
      <div className="lg:h-[300px] flex flex-col items-center justify-center">
        <div className="relative overflow-hidden bg-no-repeat bg-cover lg:w-[300px] lg:h-[220px]">
          <img src={item.post_img} alt="NewsHero" className="w-full h-full" />
        </div>
        <div className="text-[16px] font-[700] text-[#121221] mt-2">
          <Link to={`pdetail/${item.slug}`}>
            {readMore ? item.title : `${item.title.substring(0, 90)} `}
            {item.title.substring(0, 180) ? (
              ""
            ) : (
              <button onClick={() => setReadMore(!readMore)}>
                {readMore ? "" : "batafsil"}
              </button>
            )}
          </Link>
        </div>
      </div>
      <p className="flex items-center gap-1 text-[12px] justify-end text-gray-500 lg:mt-[10px] md:mt-[15px] mt-[40px]">
        <BiCalendar size={14} /> {dateMDY}
      </p>
    </div>
  );
};

export default NewsHeroItem;
