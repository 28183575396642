import React from "react";
import { Link } from "react-router-dom";

const NormativItem = ({ item }) => {
  return (
    <div className="shadow-sm p-4 border rounded-md mt-4 hover:bg-gray-100 duration-500">
      <Link
        to={`/ndetail/normativ-hujjatlar/detail/${item.id}`}
        className="font-semibold"
      >
        {item.title}
      </Link>
    </div>
  );
};

export default NormativItem;
