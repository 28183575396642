import React, { useState } from "react";
import { BiCalendar } from "react-icons/bi";
import { Link } from "react-router-dom";

const AllNewsItem = ({ item }) => {
  const [readMore] = useState(false);
  console.log(item);

  let date = new Date(item.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;
  return (
    <div className="m-2 hover:shadow-2xl duration-700 p-2 shadow-md border border-gray-200 lg:w-[300px]">
      <div className="lg:w-[280px] lg:h-[200px]">
        <img src={item.post_img} className="w-full h-full" />
      </div>
      <div className="mt-2">
        <Link
          to={`pdetail/${item.slug}`}
          className="text-[16px] font-[700] text-[#121221]"
        >
          {readMore ? item.title : `${item.title.substring(0, 85)}`}
        </Link>
      </div>
      <p className="flex gap-1 items-center justify-end text-gray-500 mt-[20px] text-[12px]">
        <BiCalendar />
        {dateMDY}
      </p>
    </div>
  );
};

export default AllNewsItem;
